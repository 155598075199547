.nav-item {
    list-style: none;
    display:flex;
    justify-content: flex-end;
    
    &-link {
        display: flex;
        align-items: center;
        color: $color-white;
        text-decoration: none;
        font-size: 2.5rem;

        .link-label {
            font-size: 15px;
            cursor: pointer;
            margin: 0 0.5rem;

            @include respond(small-phone) {
                display: none;
            }
        }        
    }
}