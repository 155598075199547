.menu-item {
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: $color-white;
    font-size: 1.5rem;
    text-decoration: none;

    &:hover {
        color: $color-primary;
    }
}