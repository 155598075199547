.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    margin-top: 8rem;
    z-index: 1;
    
    &-action-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

    }
    
    &-detail {
        justify-content: left;
    }

    &-container {
        display: grid;
        grid-template-columns: repeat(7,1fr);
        gap: 1rem;
        justify-content: center;
        width: 100%;
        padding: 3rem 1rem;

        &-detail {
            grid-template-columns: repeat(4,1fr);
            width: 65%;

            @include respond(tab-land) {
                grid-template-columns: repeat(4,1fr)!important;
            }
    
            @include respond(big-desktop) {
                grid-template-columns: repeat(6,1fr)!important;
            }

            @include respond(small-phone) {
                width: 100%
            }
    
            @include respond(phone) {
                width: 100%
            }
            
            @include respond(tab-port) {
                width: 100%
            }
        }

        @include respond(small-phone) {
            grid-template-columns: 1fr;
        }

        @include respond(phone) {
            grid-template-columns: repeat(3,1fr);
        }
        @include respond(tab-port) {
            grid-template-columns: repeat(5,1fr);
        }

        @include respond(tab-land) {
            grid-template-columns: repeat(6,1fr);
        }

        @include respond(big-desktop) {
            grid-template-columns: repeat(8,1fr);
        }
    }


    &-card {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        border: 0.2rem solid $color-white;
        height: 15rem;

        &-active {
            border: 0.2rem solid $color-primary;
        }

        &-image {
            height: 100%;
            border-radius: 1rem;
        }

        &:hover {
            border: 0.2rem solid $color-primary;
            cursor: pointer;
        }

        @include respond(small-phone) {
           height: 20rem;
        }

    }

    .show-more {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;

        &-detail {
            width: 65%;
        }

        @include respond(tab-port) {
            padding: 0 2rem;
        }

        @include respond(phone) {
            padding: 0 2rem;
        }

        @include respond(small-phone) {
            padding: 0 2rem;
        }

    }
}