/*
0 - 500px:      Small phone
500 - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
1200 - 1800px:  Normal desktop
1800px + :      Big desktop
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 56.3125em) and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #212529;
}

.button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 5rem;
  background-color: #f36e22;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  border: 0;
  text-decoration: none;
}
.button-primary-text {
  color: #fff;
  text-decoration: none;
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  .button-primary {
    width: 100%;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .button-primary {
    width: 100%;
  }
}
@media (max-width: 31.25em) {
  .button-primary {
    width: 100%;
  }
}

.nav-item {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.nav-item-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 2.5rem;
}
.nav-item-link .link-label {
  font-size: 15px;
  cursor: pointer;
  margin: 0 0.5rem;
}
@media (max-width: 31.25em) {
  .nav-item-link .link-label {
    display: none;
  }
}

.dropdown {
  position: absolute;
  top: 8rem;
  width: 25rem;
  background-color: #0c0c0c;
  overflow: hidden;
  padding: 1rem;
}

.menu-item {
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
}
.menu-item:hover {
  color: #f36e22;
}

.design-sets-dropdown {
  position: absolute;
  top: 8rem;
  width: 20rem;
  background-color: #0c0c0c;
  overflow: hidden;
  padding: 1rem;
}

.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
}
.search-bar-text {
  height: 100%;
  padding: 0 1rem;
}
.search-bar-text:focus {
  outline: none;
}
.search-bar-icon {
  position: absolute;
  right: 0.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin-top: 8rem;
  z-index: 1;
}
.home-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.home-detail {
  justify-content: left;
}
.home-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  justify-content: center;
  width: 100%;
  padding: 3rem 1rem;
}
.home-container-detail {
  grid-template-columns: repeat(4, 1fr);
  width: 65%;
}
@media (min-width: 56.3125em) and (max-width: 75em) {
  .home-container-detail {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 112.5em) {
  .home-container-detail {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (max-width: 31.25em) {
  .home-container-detail {
    width: 100%;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .home-container-detail {
    width: 100%;
  }
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  .home-container-detail {
    width: 100%;
  }
}
@media (max-width: 31.25em) {
  .home-container {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .home-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  .home-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 56.3125em) and (max-width: 75em) {
  .home-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 112.5em) {
  .home-container {
    grid-template-columns: repeat(8, 1fr);
  }
}
.home-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 0.2rem solid #fff;
  height: 15rem;
}
.home-card-active {
  border: 0.2rem solid #f36e22;
}
.home-card-image {
  height: 100%;
  border-radius: 1rem;
}
.home-card:hover {
  border: 0.2rem solid #f36e22;
  cursor: pointer;
}
@media (max-width: 31.25em) {
  .home-card {
    height: 20rem;
  }
}
.home .show-more {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}
.home .show-more-detail {
  width: 65%;
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  .home .show-more {
    padding: 0 2rem;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .home .show-more {
    padding: 0 2rem;
  }
}
@media (max-width: 31.25em) {
  .home .show-more {
    padding: 0 2rem;
  }
}

.openConnection {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8rem;
}
.openConnection-container {
  width: 80%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.openConnection-text {
  font-size: 2rem;
  margin-right: 2rem;
}

.header {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background: #0c0c0c;
  z-index: 2;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.header-logo {
  display: flex;
}
.header-logo svg {
  height: 3rem;
}
@media (max-width: 31.25em) {
  .header-logo svg {
    height: 2rem;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .header-logo svg {
    height: 2rem;
  }
}
.header-menu {
  display: flex;
  gap: 2rem;
}
.header-menu-item {
  display: flex;
  color: #fff;
  text-decoration: none;
}

.detailPage {
  position: absolute;
  right: 0;
  height: 100%;
  width: 35%;
  padding: 0 2.5rem;
  background-color: #0c0c0c;
  color: #fff;
}
@media (max-width: 31.25em) {
  .detailPage {
    width: 100%;
  }
}
@media (min-width: 31.3125em) and (max-width: 41.25em) {
  .detailPage {
    width: 100%;
  }
}
@media (min-width: 41.3125em) and (max-width: 56.25em) {
  .detailPage {
    width: 100%;
  }
}
.detailPage .close-icon {
  position: absolute;
  top: 1rem;
  left: 3.5rem;
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: #212529;
}
.detailPage-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: black;
}
.detailPage-image-wrapper img {
  width: 60%;
  max-height: auto;
}
.detailPage-properties {
  padding: 2rem 0;
}
.detailPage-properties-top {
  display: flex;
  justify-content: space-between;
}
.detailPage-properties-button {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 5rem;
  background-color: #f36e22;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  border: 0;
  text-decoration: none;
}

.footer {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 8rem;
  background-color: #0c0c0c;
  margin-top: auto;
}
.footer-container {
  display: flex;
  align-items: center;
  width: 80%;
}

.spinner {
  margin: 10rem auto 0;
  width: 7rem;
  text-align: center;
}

.bottom {
  margin: 2rem auto 10rem;
  width: 7rem;
  text-align: center;
}

.spinner > div {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.message {
  display: flex;
  width: 80%;
  height: 5rem;
  background: #fdecea;
  align-self: center;
  align-items: center;
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;
}
.message-content-text {
  color: #915852;
  margin-left: 2rem;
}


