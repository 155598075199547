.openConnection {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 8rem;

    &-container {
        width: 80%;
        display:flex;
        align-items: center;
        margin-top: 2rem;
    }

    &-text {
        font-size: 2rem;
        margin-right: 2rem;
    }
}