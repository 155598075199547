// Media query manager
/*
0 - 500px:      Small phone
500 - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
1200 - 1800px:  Normal desktop
1800px + :      Big desktop
*/

@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media (max-width: 31.25em) { @content };        //500px
    };
    @if $breakpoint == phone {
        @media (min-width: 31.3125em) and (max-width: 41.25em) { @content };        //660px
    };
    @if $breakpoint == tab-port {
        @media (min-width: 41.3125em) and (max-width: 56.25em) {@content};       //900px
    };
    @if $breakpoint == tab-land {
        @media (min-width:56.3125em) and (max-width: 75em) {@content};          //1200px
    };
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) { @content };       //1800px
    };
}