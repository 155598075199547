*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
    // This defines what 1 rem is
  font-size: 62.5%;

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}