.header {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 8rem;
    background: $color-black;
    z-index: 2;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
    }

    &-logo {
        display: flex;
        
        svg {
            height: 3rem;
        }

        @include respond(small-phone) {
            svg {
                height: 2rem;
            }
        }

        @include respond(phone) {
            svg {
                height: 2rem;
            }
        }

    }

    &-menu {
        display:flex;
        gap: 2rem;

        &-item {
            display: flex;
            color: $color-white;
            text-decoration: none;
        }
    }
}