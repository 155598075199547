.message {
    display: flex;
    width: 80%;
    height: 5rem;
    background: #fdecea;
    align-self: center;
    align-items: center;
    padding: 2rem;
    margin: 2rem;
    border-radius: 0.5rem;

    &-content-text {
        color: #915852;
        margin-left: 2rem;
    }
}