.search-bar {
    position:relative;
    display: flex;
    justify-content: center;

    &-text {
        height: 100%;
        padding: 0 1rem;
    }
    &-text:focus {
        outline: none;
    }

    &-icon {
        position: absolute;
        right: .2rem;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

    }
}