.detailPage {
    position: absolute;
    right: 0;
    height: 100%;
    width: 35%;
    padding: 0 2.5rem;
    background-color: $color-black;
    color: $color-white;

    @include respond(small-phone) {
        width: 100%
    }

    @include respond(phone) {
        width: 100%
    }
    
    @include respond(tab-port) {
        width: 100%;
    }

    .close-icon {
        position: absolute;
        top: 1rem;
        left: 3.5rem;
        height: 3.6rem;
        width: 3.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        background-color: $color-grey-dark;
    }

    &-image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: black;

        img {
            width:60%;
            max-height: auto;
        }
    }

    &-properties {
        padding: 2rem 0;

        &-top {
            display: flex;
            justify-content: space-between;
        }

        &-button {
            display: flex;
            align-items: center;
            height: 4rem;
            padding: 0 5rem;
            background-color: $color-primary;
            color: $color-white;
            font-size: 1.5rem;
            cursor: pointer;
            border: 0;
            text-decoration: none;
        }
    }

}