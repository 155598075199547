.button-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    padding: 0 5rem;
    background-color: $color-primary;
    color: $color-white;
    font-size: 1.5rem;
    cursor: pointer;
    border: 0;
    text-decoration: none;

    &-text {
        color: $color-white;
        text-decoration: none;
    }

    @include respond(tab-port) {
        width: 100%;
     }

     @include respond(phone) {
        width: 100%;
     }
     @include respond(small-phone) {
        width: 100%;
     }
}

