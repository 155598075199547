.footer {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    width: 100%;
    min-height: 8rem;
    background-color: $color-black;
    margin-top: auto;

    &-container {
        display: flex;
        align-items: center;
        width: 80%;
    }
}